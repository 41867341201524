import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { v4 } from "uuid";
import "@fortawesome/fontawesome-free/css/all.css";

// import SEO from "../components/seo";
// import NavBar from "../components/navbar.js";
// import {Footer} from "../components/footer.js";
import AwesomeCard from "./awesome-card.js";
import "./awesome.scss";

// Get environment variable
let REACT_APP_API_URL = "http://localhost:5000";

if (process.env.REACT_APP_API_URL) {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL;
}

// Get today's date in YYYY-MM-DD format
const TODAY = new Date().toISOString().slice(0, 10);

function getPrevDate(prev) {
  const date = new Date(prev);
  date.setDate(date.getDate() - 1);
  return date.toISOString().slice(0, 10);
}

const useGetAwesomeVideos = (day, setDay, emptyCounter, setEmptyCounter) => {
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // if (page === 1 && videos.length > 0) {
    // }
    // console.log("CALLED page ", page)
    setLoading(true);
    axios({
      method: "GET",
      url: REACT_APP_API_URL + `/videos/day/${day}`,
    })
      .then((res) => {
        let new_vids = res.data.data;
        setVideos((prev) => [...prev, ...new_vids]);
        setHasNextPage(res.data.data.length > 0);
        setLoading(false);

        // If new vids is empty, then we need to go back a day
        if (new_vids.length === 0) {
          setDay((prev) => getPrevDate(prev));
          setEmptyCounter((prev) => prev + 1);
        } else {
          setEmptyCounter(0);
        }
        // console.log("Fetched page " + page)
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [day, setDay, setEmptyCounter]);
  return { hasNextPage, loading, videos };
};

const AwesomePage = () => {
  const [day, setDay] = useState(TODAY);
  const [emptyCounter, setEmptyCounter] = useState(0);
  const { videos, loading, hasNextPage } = useGetAwesomeVideos(
    day,
    setDay,
    emptyCounter,
    setEmptyCounter
  );
  const observer = useRef();
  console.log(videos);
  const lastVidRef = useCallback(
    (vid) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((vids) => {
        // Also account for when there are no more videos
        // if (vids[0].isIntersecting) {
        if (vids[0].isIntersecting && hasNextPage) {
          setDay((prev) => getPrevDate(prev));
        }
      });
      if (vid) observer.current.observe(vid);
    },
    [loading, hasNextPage]
  );

  return (
    <>
      {/* <SEO
                title="Made with Manim"
                description="Lectures and explainers on YouTube which are made with Manim."
            /> */}
      {/* <NavBar /> */}
      <main className="main-div">
        <h1>（（（　ＭＵＮＣＨ　）））</h1>
        <p>
          munch lets you scrape and unify your feeds in one place. this instance
          belongs to <a href="https://github.com/osolmaz">me</a>. reach out to
          me if you want to get involved.
        </p>
        <div className="home">
          <div id="videos" className="card-container">
            {videos.map((vid, i) => {
              if (videos.length === i + 1) {
                return (
                  <AwesomeCard ref={lastVidRef} key={`v${i}`} info={vid} />
                );
              }
              return <AwesomeCard key={`v${i}`} info={vid} />;
            })}
            {loading &&
              [...Array(6)].map((_) => {
                return (
                  <div key={v4()} className="video-card">
                    {[...Array(4)].map((_) => {
                      return (
                        <div key={v4()} className="loading-container">
                          <div className="content-loading" />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default AwesomePage;
